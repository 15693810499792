<div class="resetpwd-completed" id="main">
    <app-header></app-header>
    <div class="main-body">
        <div class="d-flex justify-content-center">
            <div class="content">
                <div class="check-icon"></div>

                <div class="heading">
                    <h4>{{ 'resetpwd_completed.title' | translate}}</h4>
                    <p [innerHTML]="'resetpwd_completed.text' | translate"></p>
                </div>

                <button class="bt-registration" pButton pRipple icon="pi pi-angle-right" iconPos="right"
                    [label]="'go-to-login' | translate" (click)="goTo()"></button>
            </div>
        </div>
    </div>
</div>