<div class="delete-profile" id="main">
    <app-header></app-header>
    <div class="main-body">
        <div class="d-flex justify-content-center">
            <div class="content">

                <div class="v1 x" style="margin: 0 16px;">
                    <div class="doc-title"><h2>Data Protection Statement</h2></div>
                    <div class="tag-paragraph">
                        <p>Name of the Application: Smartboat</p>
                    </div>
                    <div class="tag-paragraph">
                        <p>Developer name: Azimut Benetti</p>
                    </div>
                    <br>
                    <div class="tag-paragraph">
                        <p>In order to better realize the user's personal privacy rights, we provide the "delete account" function in the App. Users can find the entry to delete personal information (account) through the following path: App - Profile - Delete profile</p>
                    </div>
                    <br>
                    <div class="tag-paragraph">
                        <p>For each registered user, we keep the following data:</p>
                        <ul>
                            <li>
                                Registered Account Data: email address, phone number, birth date, login credentials, country code, language preference
                            </li>
                            <li>
                                Mobile Device Information: push notification identifier
                            </li>
                            <li>
                                Usage Data: messages sent/received, log files
                            </li>
                        </ul>
                    </div>
                    <div class="tag-paragraph">
                        <p>When a user deletes his/her account, we will delete all Registered Account Data. Mobile Device Information as well as log files are kept in a pseudonymized form: they contain an internal user id that cannot be tracked to the user's identity once the account is deleted.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
