import { Component, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-app-store',
  templateUrl: './app-store.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppStoreComponent {

  public env = environment;

  constructor() {
    
  }

}
