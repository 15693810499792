import { Component } from '@angular/core';
import { HeaderComponent } from '../../_components/header/header.component';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent {

}
