<div class="appstore" id="main">
    <app-header></app-header>
    <div class="main-body">
        <div class="d-flex justify-content-center">
            <div class="content">

                <div class="iphone">
                    <img class="azimut-app" src="../../../assets/images/iphone-azimut.png" alt="azimut-app">
                    <div class="circle"></div>
                </div>

                <div class="title">
                    <h2>Scarica l'App Azimut Gratuitamente!</h2>
                    <p>seleziona il tuo store:</p>
                </div>
                
                <div class="buttons">
                    <div class="d-flex justify-content-center align-items-center">
                        <a [href]="env.deeplink" class="store play"><img src="../../../assets/images/playstore.png" alt="Play store"></a>
                        <a [href]="env.deeplink" class="store apple"><img src="../../../assets/images/applestore.png" alt="Apple store"></a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
