<div class="registration" id="main">
    <app-header></app-header>
    <div class="main-body">
        <div class="d-flex justify-content-center">
            <div class="content">
                <div class="heading">
                    <h2>{{ 'registration.title' | translate}}</h2>
                    <p [innerHTML]="'registration.text' | translate"></p>
                </div>

                <form [formGroup]="form" (submit)="onSubmit()">
                    <div class="fieldset">
                        <label for="name">{{'registration.name' | translate}}</label>
                        <input type="text" id="name" pInputText [placeholder]="'registration.name' | translate"
                            formControlName="name" />
                    </div>
                    <div class="fieldset">
                        <label for="surname">{{'registration.surname' | translate}}</label>
                        <input type="text" id="surname" pInputText [placeholder]="'registration.surname' | translate"
                            formControlName="surname" />
                    </div>
                    <div class="fieldset">
                        <label for="birthDate">{{'registration.dateofbirth' | translate}}</label>
                        <span class="p-input-icon-right">
                            <i class="pi pi-calendar"></i>
                            <p-calendar formControlName="birthDate"
                                [placeholder]="'registration.dateofbirth' | translate" [maxDate]="maxDate"
                                [readonlyInput]="true"></p-calendar>
                        </span>
                    </div>
                    <div class="fieldset" #fieldsetPassword>
                        <label for="password">{{'registration.password' | translate}}</label>
                        <span class="p-input-icon-right">
                            <i class="eye pi pi-eye" style="cursor: pointer;"
                                (click)="switchIconEye(fieldsetPassword)"></i>
                            <input type="password" id="password" pInputText
                                [placeholder]="'registration.password' | translate" formControlName="password" />
                        </span>
                    </div>
                    <div class="fieldset" #fieldsetRipetiPassword>
                        <label for="repeatpassword">{{'registration.repeatpassword' | translate}}</label>
                        <span class="p-input-icon-right">
                            <i class="eye pi pi-eye" style="cursor: pointer;"
                                (click)="switchIconEye(fieldsetRipetiPassword)"></i>
                            <input type="password" id="repeatpassword" pInputText
                                [placeholder]="'registration.repeatpassword' | translate"
                                formControlName="repeatpassword" />
                        </span>
                    </div>
                    <div class="fieldset">
                        <label for="country">{{'registration.country' | translate}}</label>
                        <p-dropdown [options]="countries" formControlName="country" [editable]="true" optionLabel="name"
                            placeholder="{{'registration.country' | translate}}" (onChange)="filter($event)"></p-dropdown>
                    </div>
                    <div class="fieldset">
                        <label for="language">{{'registration.language' | translate}}</label>
                        <p-dropdown [options]="languages" formControlName="language" optionLabel="name" placeholder="{{'registration.language' | translate}}" styleClass="languageDrop">
                            <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center selectedItem" *ngIf="form.value.language">
                                    {{ form.value.language.name | translate}}
                                </div>
                            </ng-template>
                            <ng-template let-country pTemplate="item">
                                <div class="flex align-items-center">
                                    {{ country.name | translate}}
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="check-privacy">
                        <p>{{'registration.check-privacy-prelink' | translate}}<a [href]="link" target="_blank">{{'registration.check-privacy-link' | translate}}</a>{{'registration.check-privacy-postlink' | translate}}</p>
                    </div>
                    <button class="bt-registration" pButton pRipple type="submit"
                        [label]="'registration.submit' | translate"></button>
                </form>
            </div>
        </div>
    </div>
</div>