import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Lang } from '../_models';
import { PrimeNGConfig } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  readonly DEFAULT_LANG_VALUE = 'en';
  readonly allowLanguages = [this.DEFAULT_LANG_VALUE, 'it', 'de', 'es', 'fr'];

  constructor(private translate: TranslateService, private config: PrimeNGConfig) { }

  init() {
    let lang = this.translate.getBrowserLang();
    if (lang && this.allowLanguages.includes(lang)) {
      this.translate.setDefaultLang(lang);
      this.changeLanguage(lang);
    } else {
      lang = this.DEFAULT_LANG_VALUE;
      this.translate.setDefaultLang(this.DEFAULT_LANG_VALUE);
      this.changeLanguage(lang);
    }

  }

  changeLanguage(lang: Lang | string) {
    this.translate.use(lang);
    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
  }
}
