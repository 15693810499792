import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { RequestRegistration } from '../_models';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  constructor(private http: HttpClient) {}

  getCountriesOld(): Observable<any> {
    let url = 'https://restcountries.com/v3.1/all';
    return this.http.get<any>(url);
  }

  getCountries(): Observable<any> {
    let url = `${environment.host}/users/countries/`;
    return this.http.get<any>(url);
  }

  UserRegistration(token: string, data: RequestRegistration): Observable<void> {
    let url = `${environment.host}/users/verify/${token}`;
    return this.http.post<void>(url, data);
  }
}
