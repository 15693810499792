import { Component, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-resetpwd-completed',
  templateUrl: './resetpwd-completed.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ResetpwdCompletedComponent {

  public env = environment;

  constructor() {}

  goTo() {
    window.open(this.env.deeplink, "_blank");
  }
}
