<div class="not-found" id="main">
    <app-header></app-header>
    <div class="main-body">
        <div class="d-flex justify-content-center">
            <div class="content">
                <h2>{{ 'notfound.title' | translate}}</h2>
                <p>{{ 'notfound.text' | translate}}</p>
            </div>
        </div>
    </div>
</div>
