import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as auth from 'firebase/auth';
import { Constants, LocalStoragerService, MyToastrService } from '../_utility';
import { User } from '../_models';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userData: any;

  // helper methods refreshToken
  private userSubject: BehaviorSubject<any | null>;
  public userOb: Observable<any | null>;
  private refreshTokenTimeout?: any;

  constructor(
    public router: Router,
    private http: HttpClient,
    private toast: MyToastrService,
    public lStorager: LocalStoragerService,
    private helperJwt: JwtHelperService,
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    this.userSubject = new BehaviorSubject<User | null>(null);
    this.userOb = this.userSubject.asObservable();

    /* Saving user data in localstorage when logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        this.user = this.userData;
        this.userSubject.next(this.userData);
        this.startRefreshTokenTimer();
      } else {
        this.user = null;
        this.userSubject.next(null);
        this.stopRefreshTokenTimer();
      }
    });
  }

  // Ritorna utente salvato nello storage
  get user(): User {
    const element = this.lStorager.getElement(Constants.Auth.USER_KEY);
    const user = element ? element : null;
    return user;
  }

  set user(user: User | null) {
    if (!user) {
      this.lStorager.removeElement(Constants.Auth.USER_KEY);
    } else {
      this.lStorager.setElement(Constants.Auth.USER_KEY, user);
    }
  }

  // Ritorna true quando utente è loggato e l'email è verifcato
  get isLoggedIn(): boolean {
    return !!this.user && !!this.token ? true : false;
  }

  // Ritorna il token o stringa vuota
  get token(): string {
    return this.user && this.user.token ? this.user.token : '';
  }

  // gestione refresh token Firebase
  isExpiredToken(): boolean {
    return this.helperJwt.isTokenExpired(this.token);
  }

  async refreshToken(): Promise<boolean> {
    try {
      let currentUser = await this.afAuth.currentUser;
      let newToken = <string>await currentUser?.getIdToken(true);
      let refreshUser = this.user;
      refreshUser.token = newToken;
      this.user = refreshUser;
      // Observable Timer refreshToken START
      this.userSubject.next(currentUser);
      this.startRefreshTokenTimer();
      return true;
    } catch {
      return false;
    }
  }

  // helper methods
  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtBase64 = this.token!.split('.')[1];
    // const jwtToken = JSON.parse(this.helperJwt.urlBase64Decode(jwtBase64)!);

    // set a timeout to refresh the token a minute before it expires
    // const expires = new Date(jwtToken.exp * 1000);
    // const timeout = expires.getTime() - Date.now() - (60 * 1000);
    // this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  // Sign in with email/password
  SignIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.SetUserData(result.user);
        this.afAuth.authState.subscribe((user) => {
          if (user) {
            this.router.navigate(Constants.Routing.DASHBOARD.routerLink);
          }
        });
      })
      .catch((error) => {
        this.toast.ERROR(Constants.TITLE_ERROR, error.message);
      });
  }
  // Sign up with email/password
  SignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
                up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user);
      })
      .catch((error) => {
        this.toast.ERROR(Constants.TITLE_ERROR, error.message);
      });
  }
  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.toast.INFO(
          Constants.TITLE_INFO,
          'Ti è stata inviata una mail di verifia'
        );
      });
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.toast.WARNING(
          Constants.TITLE_WARNING,
          'Password reset email sent, check your inbox.'
        );
      })
      .catch((error) => {
        this.toast.ERROR(Constants.TITLE_ERROR, error);
      });
  }
  //Complete password reset
  SendNewPassword(code: string, newPassword: string) {
    return this.afAuth
      .confirmPasswordReset(code, newPassword)
      .then(() => {
        this.toast.SUCCESS(Constants.TITLE_SUCCESS,'Password correttamente cambiata.');
        this.router.navigate(Constants.Routing.RESETPWD_COMPLETED.routerLink);
      })
      .catch((error) => {
        this.toast.ERROR(Constants.TITLE_ERROR, error);
      });
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider()).then((res: any) => {
      this.router.navigate(Constants.Routing.DASHBOARD.routerLink);
    });
  }
  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.SetUserData(result.user);
        this.router.navigate(Constants.Routing.DASHBOARD.routerLink);
      })
      .catch((error) => {
        this.toast.ERROR(Constants.TITLE_ERROR, error);
      });
  }
  /* Setting up user data when sign in with username/password, 
    sign up with username/password and sign in with social auth  
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  async SetUserData(user: any) {
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      token: await user.getIdToken(),
    };
    return userData;
  }
  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      this.user = null;
      this.userSubject.next(null);
      this.stopRefreshTokenTimer();
      this.router.navigate(Constants.Routing.LOGIN.routerLink);
    });
  }

  // API
  profile(): Observable<Partial<User>> {
    let url = `${environment.host}${environment.apiUrl}/users`;
    return this.http.get(url);
  }
}
