export class RequestRegistration {
  userInfo?: RequestUserInfo;
  password?: string;
}

class RequestUserInfo {
  name?: string;
  surname?: string;
  birthDate?: string;
  country?: string;
  language?: string;
}
