<div class="reset-password" id="main">
    <app-header></app-header>
    <div class="main-body">
        <div class="d-flex justify-content-center">
            <div class="content">
                <div class="heading">
                    <!-- <h2>{{ 'reset_password.title' | translate}}</h2> -->
                    <p [innerHTML]="'reset_password.text' | translate"></p>
                </div>
                <form [formGroup]="form" (submit)="onSubmit()">
                    <div class="fieldset" #fieldsetPassword>
                        <label for="password">{{'reset_password.password' | translate}}</label>
                        <span class="p-input-icon-right">
                            <i class="eye pi pi-eye" style="cursor: pointer;"
                                (click)="switchIconEye(fieldsetPassword)"></i>
                            <input type="password" id="password" pInputText
                                [placeholder]="'reset_password.password' | translate" formControlName="password" />
                        </span>
                    </div>
                    <div class="fieldset" #fieldsetRipetiPassword>
                        <label for="repeatpassword">{{'reset_password.repeatpassword' | translate}}</label>
                        <span class="p-input-icon-right">
                            <i class="eye pi pi-eye" style="cursor: pointer;"
                                (click)="switchIconEye(fieldsetRipetiPassword)"></i>
                            <input type="password" id="repeatpassword" pInputText
                                [placeholder]="'reset_password.repeatpassword' | translate"
                                formControlName="repeatpassword" />
                        </span>
                    </div>
                    <button class="bt-registration" pButton pRipple type="submit" [label]="'reset_password.submit' | translate"></button>
                </form>
            </div>
        </div>
    </div>
</div>