import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../_services';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private auth: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        // if (this.auth.isExpiredToken()) {
        //     return this.auth.refreshToken();
        // }

        if (this.auth.isLoggedIn) {
            // logged in so return true
            return true;
        }

        this.auth.SignOut();
        return false;
    }

}
