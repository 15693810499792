import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-registration-completed',
  templateUrl: './registration-completed.component.html'
})
export class RegistrationCompletedComponent {

  public env = environment;

  constructor() {}

  goTo() {
    window.open(this.env.deeplink, "_blank");
  }

}
