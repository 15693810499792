import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { RegistrationService } from '../../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Constants, MyToastrService } from '../../_utility';
import { RequestRegistration } from '../../_models';
import { SpinnerService } from '../../_components/spinner/spinner.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RegistrationComponent implements OnInit {
  public form: FormGroup;
  private pattern = '^(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=[^0-9]*[0-9]).{0,}$';
  private token!: string;
  public countries: any[] = [];
  public languages: any[] = [];
  public maxDate: Date = new Date();

  public link: string = `https://europe-west1-${environment.firebase.projectId}.cloudfunctions.net/privacy-policy`

  constructor(
    private fb: FormBuilder,
    private ar: ActivatedRoute,
    private toast: MyToastrService,
    private service: RegistrationService,
    private spinner: SpinnerService,
    private router: Router,
    private transalte: TranslateService
  ) {
    this.form = this.fb.group(
      {
        name: new FormControl('', [Validators.required]),
        surname: new FormControl('', [Validators.required]),
        birthDate: new FormControl('', [Validators.required]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(this.pattern),
        ]),
        repeatpassword: new FormControl('', [Validators.required]),
        country: new FormControl('', [Validators.required]),
        language: new FormControl('', [Validators.required]),
      },
      { validators: this.checkPasswords }
    );

    this.ar.queryParams.subscribe((params) => {
      if (!!params) {
        this.token = params['token'];
      }
    });
  }

  ngOnInit(): void {
    this.getCountries();

    this.languages = [
      { name: 'language.it', code: 'it' },
      { name: 'language.en', code: 'en' },
      { name: 'language.es', code: 'es' },
      { name: 'language.fr', code: 'fr' },
      { name: 'language.de', code: 'de' },
    ];
  }

  checkPasswords: Validators = (
    group: AbstractControl
  ): ValidationErrors | null => {
    let pass = group.get('password')?.value;
    let confirmPass = group.get('repeatpassword')?.value;
    return pass === confirmPass ? null : { notSame: true };
  };

  async onSubmit() {
    if (this.form.invalid) {
      // console.log(this.form.controls);

      if (!!this.form.getError('notSame') && this.form.getError('notSame')) {
        this.form.controls['repeatpassword'].markAsTouched();
        this.form.controls['repeatpassword'].markAsDirty();
        this.form.controls['repeatpassword'].setErrors({ incorrect: true });
        this.toast.ERROR(Constants.TITLE_ERROR, 'pwd_not_match');
      }
      // else {
      //   this.toast.ERROR(Constants.TITLE_ERROR, 'form_error');
      // }

      for (const [key, value] of Object.entries(this.form.controls)) {
        // console.log(`${key}: ${value}`);
        if (value.status === 'INVALID') {
          this.form.controls[key].markAsTouched();
          this.form.controls[key].markAsDirty();
          // this.form.controls[key].setErrors({'incorrect': true});
          if (key === 'password') {
            this.toast.ERROR(Constants.TITLE_ERROR, 'pwd_error');
          }
        }
      }
      return;
    }

    if (!this.token) {
      this.toast.ERROR(Constants.TITLE_ERROR, 'Il token non è presente');
      return;
    }

    let obj: RequestRegistration = new RequestRegistration();
    obj.userInfo = {
      name: this.form.value.name,
      surname: this.form.value.surname,
      birthDate: this.formatBirthDate(this.form.value.birthDate),
      language: this.form.value.language.code,
      country: this.form.value.country.code,
    };
    obj.password = this.form.value.repeatpassword;

    this.spinner.show();
    this.service.UserRegistration(this.token, obj).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        this.router.navigate(
          Constants.Routing.REGISTRATION_COMPLETED.routerLink
        );
      },
      error: (err) => {
        this.spinner.hide();
      },
    });
  }

  filter($event: any) {
    // console.log($event);
    for(let o of this.countries) {
      if (o.name.toLowerCase() == $event.value.toLowerCase()) {
        this.form.controls['country'].setValue(o);
      }
    }
  }

  formatBirthDate(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  // Visualizza o nasconde la password
  switchIconEye(elm: HTMLDivElement) {
    let elmEye: Element = elm.getElementsByClassName('eye')[0];
    let elmInput: HTMLInputElement = elm.getElementsByTagName('input')[0];
    elmInput.type = elmInput.type == 'password' ? 'text' : 'password';
    if (elmEye.classList.contains('pi-eye')) {
      elmEye.classList.remove('pi-eye');
      elmEye.classList.add('pi-eye-slash');
    } else {
      elmEye.classList.remove('pi-eye-slash');
      elmEye.classList.add('pi-eye');
    }
  }

  getCountries() {
    this.service.getCountries().subscribe({
      next: (res: any) => {
        res.forEach((el: any) => {
          let obj: any = {};
          obj.name = el.name;
          obj.code = el.iso_cca3;
          this.countries.push(obj);
        });
        // this.countries = this.countries.sort((a, b) => a.name - b.name);
        this.countries = this.countries.sort(function (a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      },
    });
  }
}
